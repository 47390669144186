@import '_include-media.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Saira:wght@500;600;700;800;900&display=swap');

body {
  background-color: #fafafa;
  font-family: 'Roboto', sans-serif;
}

section {
  margin-top: 50px;
}

h1 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 1000;
  color: #112b3c;
}

p {
  font-weight: 600;
  color: #616161;
}

hr {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  border-width: 2px;
  opacity: 30%;
  color: #112b3c;
}

.navbar-link {
  margin-right: 20px;
  text-decoration: none;
  font-weight: 700;
  color: #112b3c;
}

.generic-btn {
  display: inline-block;
  text-decoration: none;
  background-color: #ff3800;
  color: #3f3f3f;
  text-align: center;
  font-weight: 700;
  min-width: 150px;
  padding: 15px 15px 15px 15px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.generic-btn:hover {
  background-color: #B1D0E0;
  color: #0067ff;
}

.generic-label {
  display: block;
  margin-bottom: 5px;
  color: #616161;
  font-family: 'Ubuntu', sans-serif;
}

.generic-input {
  border: 0px;
  border-radius: 5px;
  background-color: #cce2ff;
  margin-bottom: 10px;
  width: 450px;
  height: 40px;
}

.generic-input:focus {
  background-color: #B1D0E0;
  outline: none;
  transition: .5s ease;
}

.generic-textfield {
  resize: none;
  height: 350px;
}

.generic-btn-alt-color {
  background-color: #3c3c3c;
  color: #efefef;
}

.generic-btn-alt-color:hover {
  background-color: #B1D0E0;
  color: #0067ff;
}

.icon-link {
  text-decoration: none;
}

.icon-link:visited {
  text-decoration: none;
}

.job-description {
  font-size: 12px;
  width: 250px;
  margin-top: 30px;
}

.work-image {
  max-height: 100px;
}

#about {
  margin-top: 90px;
}

#about-contact-me {
  margin-right: 20px;
}

#about-btn-container {
  margin-top: 30px;
}

#abstract-curve {
  position: absolute;
  top: 160px;
  right: 400px;
}

#abstract-squares {
  position: absolute;
  top: 420px;
  right: 180px;
}
 
#contact-send-email {
  margin-right: 20px;
}

#contact-submit-btn {
  width: 100%;
  border-width: 0px;
}

#contact-btn-container {
  margin-top: 30px;
}

#footer {
  margin-top: 5px;
}

#footer-statement {
  margin-bottom: 2px;
  font-size: 12px;
}

#navbar-contact {
  width: 105px;
}

#navbar-main {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fafafa;
  border-bottom: solid;
  border-color: #112b3c;
  z-index: 1;
  margin-bottom: 60px;
}

#work-pitch {
  margin-bottom: 50px;
}

@include media(">=desktop") {
  #about-photo {
    max-width: 450px !important;
    max-height: 300px !important;
  }
}

@include media("<tablet") {

  .generic-btn {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .generic-input {
    border: 0px;
    border-radius: 5px;
    background-color: #cce2ff;
    margin-bottom: 10px;
    width: 300px;
    height: 40px;
  }
  
  .generic-input:focus {
    background-color: #B1D0E0;
    outline: none;
    transition: .5s ease;
  }
  
  .generic-textfield {
    resize: none;
    height: 350px;
  }
  .navbar-link {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .work-container {
    margin: auto;
    text-align: center;
  }
  #about-btn-container {
    text-align: center;
  }
  #about-photo {
    margin: auto;
  }
  #about-text {
    margin-bottom: 30px;
  }
  #about-photo {
    max-width: 300px;
    max-height: 200px;
  }
  #contact-btn-container {
    text-align: center;
  }
  
}

@include media(">=tablet") {

  #about-photo {
    max-width: 360px;
    max-height: 240px;
    width: auto;
    height: auto;
  }

}

@include media(">=desktop") {
  .work-image {
    filter: grayscale(100%);
  }
  
  .work-image:hover {
    filter: none;
    transition: .4s ease;
  }

}